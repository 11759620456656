<template>
  <div class="mt-12 px-6 max-h-200 overflow-y-scroll base-scrollbar pb-2">
    <AuditLoader v-if="isLoading" />

    <div v-if="!isLoading && !auditsError">
      <div class="flex pb-2 flex-col w-full border-b border-neutral-50 bg-white">
        <p class="h4">{{ auditHeader }}</p>
      </div>
      <div class="flex flex-col">
        <div class="px-3 bg-white">
          <AuditSummary :summary="auditSummary" />
        </div>

        <div class="sticky top-0 bg-white z-10 flex-row w-full justify-evenly">
          <div class="pt-4 mx-4 flex">
            <div
              class="w-1/2 text-center flex flex-row justify-center gap-2 pb-3"
              :class="{
                'border-b-2 border-bb-brand-purple text-bb-brand-purple': selectedView === 'issues',
                'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                  selectedView !== 'issues',
              }"
              @click="selectedView = 'issues'"
            >
              <ic-alert-circle />
              <p class="h5">Issues</p>
            </div>
            <div class="px-2 py-1">
              <div class="w-px h-full bg-neutral-50"></div>
            </div>
            <div
              class="w-1/2 text-center flex flex-row justify-center gap-2 pb-3"
              :class="{
                'border-b-2 border-bb-brand-purple text-bb-brand-purple': selectedView === 'categories',
                'border-b border-neutral-50 text-bb-neutral-gray hover:border-bb-brand-purple hover:text-bb-brand-purple cursor-pointer':
                  selectedView !== 'categories',
              }"
              @click="selectedView = 'categories'"
            >
              <ic-alert-circle />
              <p class="h5 font-medium">Categories</p>
            </div>
          </div>
        </div>

        <div>
          <div v-if="selectedView === 'issues'">
            <AuditCheckList
              :checks="auditDetails.checks"
              readonly
            />
          </div>
          <div
            v-if="selectedView === 'categories'"
            class="h-1/2"
          >
            <AuditCategories
              :checks="auditDetails.checks"
              :category-scores="auditSummary.score_by_category"
              readonly
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="auditsError && !isLoading">
      <div class="flex flex-col items-center text-center my-20">
        <img
          src="/content/images/brightbid/bidbot-broken.svg"
          class="mb-6"
        />
        <p class="h3">Failed to load the audit...</p>
        <p>Please try to refresh the page and try again.</p>
      </div>
    </div>
  </div>
</template>

<script>
import IcAlertCircle from 'vue-material-design-icons/AlertCircleOutline'
import { mapActions, mapState, mapGetters } from 'vuex'
import AuditLoader from '@/components/audit-components/AuditLoader'
import AuditSummary from '@/components/audit-components/AuditSummary'
import AuditCheckList from '@/components/audit-components/AuditCheckList.vue'
import AuditCategories from '@/components/audit-components/AuditCategories.vue'

export default {
  name: 'ReportsIndex',
  components: {
    IcAlertCircle,
    AuditLoader,
    AuditSummary,
    AuditCheckList,
    AuditCategories,
  },
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    auditId: {
      type: String,
      default: null,
    },
    auditNumber: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedView: 'issues',
    }
  },

  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('audit', ['audits', 'auditDetails', 'auditSummary', 'auditsError']),
    ...mapGetters('audit', ['latestCompletedAudit', 'latestCreatedAudit', 'hasPendingAudit']),
    ...mapGetters('auth', ['userAuditGoogleCredentials']),
    auditHeader() {
      if (this.isExternal && this.userAuditGoogleCredentials) {
        return `${this.userAuditGoogleCredentials.googleCustomerName + ' Audit #' + this.auditNumber}`
      } else if (this.selectedSite) {
        return `${this.selectedSite.label + ' Audit #' + this.auditNumber}`
      }
      return 'Audit not available'
    },
    identification() {
      return this.isExternal ? this.userAuditGoogleCredentials.googleCustomerId : this.selectedSite?.value
    },
  },

  async mounted() {
    this.isLoading = true

    const startTime = Date.now()

    await this.fetchAuditData()

    const fetchTime = Date.now() - startTime

    const remainingTime = Math.max(1000 - fetchTime, 0)

    setTimeout(() => {
      this.isLoading = false
    }, remainingTime)
  },

  methods: {
    ...mapActions('audit', [
      'fetchAudits',
      'fetchAuditDetails',
      'fetchAuditSummary',
      'fetchExternalAudits',
      'fetchExternalAuditDetails',
      'fetchExternalAuditSummary',
    ]),
    closeModal() {
      this.selectedSubcheck = null
      this.showModal = false
    },
    selectSubcheck(item) {
      this.selectedSubcheck = item
      this.showModal = true
    },
    ...mapActions('toast', ['loadToast']),

    async fetchAuditData() {
      // Choose the appropriate action based on `isExternal`
      if (this.isExternal) {
        await this.fetchExternalAuditDetails({
          customerId: this.identification,
          auditReportId: this.auditId,
        })
        await this.fetchExternalAuditSummary({
          customerId: this.identification,
          auditReportId: this.auditId,
        })
      } else {
        await this.fetchAuditDetails({
          siteId: this.identification,
          auditReportId: this.auditId,
        })
        await this.fetchAuditSummary({
          siteId: this.identification,
          auditReportId: this.auditId,
        })
      }
    },
  },
}
</script>
